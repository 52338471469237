<template>
  <div
    ref="serviceCatalogContainer"
    :class="['service-catalog-container', {
      preview: isOnStacksPage,
    }]"
    data-cy="service-catalog">
    <section class="searchbar">
      <div class="searchbar__search">
        <v-text-field
          v-model="search"
          :label="$t('search')"
          class="search search-field"
          data-cy="service-search-field"
          append-icon="search"/>
      </div>

      <div class="searchbar__filter">
        <v-select
          v-model="queryParams"
          :items="$static.catalogStatusItems"
          :label="$t('filter')"
          :menu-props="{
            contentClass: 'v-menu--avatar-with-two-lines',
            maxHeight: 'auto',
          }"
          class="search-field"
          data-cy="service-filter-input">
          <template #selection="{ item }">
            <!-- Key on the avatar is required to prevent an issue when switching icons from different libraries -->
            <v-avatar
              :key="`avatar-${item.icon}`"
              class="list_tile_avatar mx-1"
              :tile="true"
              size="24">
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-avatar>

            {{ item.name }}
          </template>

          <template #item="data">
            <v-list-item-avatar
              class="list_tile_avatar my-0">
              <v-icon>{{ data.item.icon }}</v-icon>
            </v-list-item-avatar>

            <v-list-item-content
              data-cy="filter-option"
              class="py-2">
              <v-list-item-title>{{ data.item.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ data.item.hint }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-select>
      </div>

      <div
        v-if="hasCreateStackBtnVisible"
        class="searchbar__button">
        <CyButton
          v-has-rights-to="'CreateServiceCatalog'"
          class="create-stack__button mb-3"
          data-cy="new-stack-button"
          icon="add"
          @click="openStackModal">
          {{ $t('createNewStack') }}
        </CyButton>
      </div>
    </section>

    <div
      v-if="loadingConfig"
      class="text-center mt-12">
      <v-progress-circular
        indeterminate
        color="secondary"
        data-cy="progress-bar"/>
      <h4
        v-if="loadingConfig"
        class="grey--text mt-6">
        {{ $t('loadingServiceConfig', { service: loadingStackName }) }}
      </h4>
    </div>

    <ul
      v-else-if="filteredStacks.length || loading"
      class="stack-list">
      <template v-if="loading">
        <div
          v-for="i in 6"
          :key="i"
          class="d-flex flex-column sk-template sk-block pa-4">
          <div class="d-flex">
            <div class="sk-block sk-img sk-dark sk-w-12 sk-h-12 mr-2"/>
            <div class="d-flex flex-column">
              <div class="sk-block sk-title sk-dark sk-w-40"/>
              <div class="sk-block sk-title sk-dark sk-w-16 mt-2"/>
            </div>
          </div>
          <div class="sk-block sk-title sk-dark sk-full-width mt-8"/>
          <div class="sk-block sk-title sk-dark sk-full-width mt-2"/>
          <div class="sk-block sk-title sk-dark sk-w-48 mt-2"/>
          <div class="d-flex mt-16 justify-end">
            <div class="sk-block sk-title sk-dark sk-w-16 mr-2"/>
            <div class="sk-block sk-title sk-dark sk-w-24"/>
          </div>
        </div>
      </template>
      <template v-else>
        <v-lazy
          v-for="stack in filteredStacks"
          :key="stack.ref"
          :options="{ rootMargin: '200px' }"
          tag="li">
          <CyWizardServiceCard
            data-cy="service-card"
            :service="stack"
            show-use-btn
            show-details-btn
            :details-btn-action="() => { previewedStack = stack }"
            @selected="handleWhenSelected"
            @show-import-progress-modal="handleStackImportProgressModalShowing"/>
        </v-lazy>
      </template>
    </ul>

    <v-row v-else>
      <v-col class="text-center mt-12">
        <v-icon
          x-large
          class="mb-4 mt-4">
          do_not_disturb
        </v-icon>

        <h4 class="grey--text">
          {{ $t('noServiceFound') }}
        </h4>
      </v-col>
    </v-row>

    <CyModal
      v-if="showStackDependenciesErrorsModal"
      :header-title="$tc('selectStackModalHeader', showStackDependenciesErrorsModal.length)"
      :action-btn-func="() => selectStackAndLoadConfig(selectedStack)"
      :action-btn-text="$t('selectStackModalConfirmBtn')"
      :cancel-btn-text="$t('selectStackModalCancelBtn')"
      :cancel-btn-func="() => $toggle.showStackDependenciesErrorsModal(false)"
      modal-type="warning">
      <template slot="default">
        <p v-html="$sanitizeHtml($t('selectStackModalContent', { stackName: selectedStack.name }))"/>
        <span>
          {{ $t('selectStackModalSubContent') }}
        </span>
        <CyTagList
          class="mt-2"
          variant="default"
          :tags="missingDependencyTags"
          small/>
      </template>
    </CyModal>

    <CyInfraImportProgressModal
      v-if="showImportProgressModal"
      has-cancel-btn-visible
      :canonical-or-ref="_.$get(importedStack, 'ref', '')"/>

    <CyModal
      v-if="showCreateStackModal"
      :action-btn-hidden="!_.isEmpty(catalogRepositories) || !$cycloid.permissions.canDisplay('CreateServiceCatalogSource')"
      :action-btn-text="$t('createCatalogRepository')"
      action-btn-icon="add"
      :action-btn-func="() => $router.push({ name: 'newCatalogRepository' })"
      :cancel-btn-func="() => $toggle.showCreateStackModal(false)"
      :width="554"
      :header-title="_.isEmpty(catalogRepositories) ? $t('beforeCreatingStacks') : $t('createNewStack')">
      <div
        v-if="!_.isEmpty(catalogRepositories)"
        class="create-stack-modal">
        <p class="mb-8">
          <strong>{{ $t('stacksAre') }}</strong> <span>{{ $t('usingStacks') }}</span>
          <a
            class="cy-link"
            target="_blank"
            :href="$docLinks.infraImport.createAStack"
            rel="noopener noreferrer">
            {{ $t('ReadMore') }}
          </a>
        </p>

        <div class="d-flex justify-space-between">
          <div
            class="creation-method"
            data-cy="design-stack-button"
            @click.stop="$router.push({ name: 'stackFromTemplate' })">
            <h3>{{ $t('createFromTemplate') }}</h3>
            <div class="text--default">
              {{ $t('startWithPredefinedTemplate') }}
            </div>
          </div>
          <div
            class="creation-method ml-6"
            data-cy="import-infra-button"
            @click.stop="$router.push({ name: 'infraImport' })">
            <h3>{{ $t('importInfra') }}</h3>
            <div class="text--default">
              {{ $t('createYourStack') }}
            </div>
          </div>
        </div>

        <p class="mt-8 mb-2">
          {{ $t('createFromCommandLine') }}
        </p>
      </div>

      <div
        v-else
        class="create-stack-modal--no-repos">
        <div v-text="$t('requiredCatalogRepositories')"/>
      </div>
    </CyModal>

    <portal
      v-if="previewedStack"
      to="side-panel">
      <div class="side-panel-backdrop"/>
      <v-slide-x-reverse-transition>
        <v-card
          v-click-outside="{
            handler: closePreview,
            include: getClickOutsideExceptions,
          }"
          aria-label="Stack preview"
          role="region"
          class="side-panel">
          <CyStackPreview
            :stack="previewedStack"
            @close="closePreview"
            @select="handleWhenSelected(previewedStack)"/>
        </v-card>
      </v-slide-x-reverse-transition>
    </portal>
  </div>
</template>

<script>
import CyTagList from '@/components/tag-list.vue'
import CyWizardServiceCard from '@/components/wizard/service-card.vue'
import CyStackPreview from '@/components/stack-preview'
import { pagination as createAPIPage } from '@/utils/api'
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import { gtmStacksEvents } from '@/utils/helpers/analytics'

export default {
  name: 'CyWizardServiceCatalog',
  components: {
    CyInfraImportProgressModal: () => import('@/components/infra-import/progress-modal'),
    CyTagList,
    CyWizardServiceCard,
    CyStackPreview,
  },
  props: {
    stackRef: {
      type: String,
      default: '',
    },
    hasCreateStackBtnVisible: {
      type: Boolean,
      default: true,
    },
  },
  data: ({ $route }) => ({
    search: $route.query?.filter || '',
    queryParams: {},
    loading: true,
    loadingConfig: false,
    loadingStackName: '',
    showStackDependenciesErrorsModal: false,
    showCreateStackModal: false,
    selectedStack: null,
    importedStack: null,
    previewedStack: null,
  }),
  computed: {
    ...mapState({
      stacks: (state) => state.organization.available.stacks,
      catalogRepositories: (state) => state.organization.available.catalogRepositories,
    }),
    ...mapState('organization/stack', {
      stackDependenciesErrors: (state) => state.errors.dependencies,
    }),
    ...mapState('organization/infraImport', {
      showImportProgressModal: (state) => state.showImportProgressModal,
    }),
    ...mapGetters('organization/stack', [
      'stack',
      'stackConfig',
    ]),
    $static () {
      return {
        pagination: {
          page: 1,
          rowsPerPage: 100,
        },
        catalogStatusItems: [
          {
            name: this.$t('routes.stacks'),
            hint: this.$t('stacks.catalogStatus.allHint'),
            value: {},
            icon: 'public',
          },
          {
            name: this.$t('stacks.catalogStatus.publicName'),
            hint: this.$t('stacks.catalogStatus.publicHint'),
            value: { catalogStatus: 'public' },
            icon: 'remove_red_eye',
          },
          {
            name: this.$t('stacks.catalogStatus.privateName'),
            hint: this.$t('stacks.catalogStatus.privateHint'),
            value: { catalogStatus: 'private' },
            icon: 'folder_special',
          },
          {
            name: this.$t('stacks.catalogStatus.ownName'),
            hint: this.$t('stacks.catalogStatus.ownHint'),
            value: { catalogOwn: true },
            icon: 'fa-sitemap',
          },
          {
            name: this.$t('stacks.catalogStatus.trustedName'),
            hint: this.$t('stacks.catalogStatus.trustedHint'),
            value: { trusted: true },
            icon: 'verified_user',
          },
          {
            name: this.$t('stacks.catalogStatus.infraImportName'),
            hint: this.$t('stacks.catalogStatus.infraImportHint'),
            value: { importStatus: true },
            icon: 'mdi-cloud-download',
          },
        ],
      }
    },
    filteredStacks () {
      const isMatch = (value) => value.toLowerCase().includes(this.search.toLowerCase())

      return _(this.stacks)
        .sortBy([
          ({ author }) => author !== 'Cycloid',
          'name',
        ]).filter((stack) => _(['name', 'canonical', 'description', 'author', 'keywords'])
          .flatMap((key) => stack[key])
          .some((field) => isMatch(field)),
        // TODO: FE#6435 switch to server side filtering once the import_status is supported
        ).filter((stack) => this.queryParams.importStatus ? !!stack?.import_status : true)
        .value()
    },
    missingDependencyTags () {
      if (_.$isEmpty(this.stackDependenciesErrors)) return []
      return this.stackDependenciesErrors.map((dependencyError) => {
        const errorChunks = dependencyError.split(' ')
        const stackCanonical = errorChunks.find((chunk) => chunk.includes(':')) || ''
        const stackCanonicalChunks = stackCanonical.replace(/'/g, '').split(':')
        return {
          label: stackCanonicalChunks[0],
          content: stackCanonicalChunks[1],
        }
      })
    },
    isOnStacksPage () {
      return this.$route.name === 'stacks'
    },
  },
  watch: {
    queryParams () {
      this.getStacks()
    },
    stackRef: {
      async handler (newVal) {
        if (_.isEmpty(newVal)) return
        this.loading = true
        await this.GET_STACK({ stackRef: newVal })
        this.loading = false
        if (!_.isEmpty(this.stack)) this.selectStackAndLoadConfig(this.stack)
      },
      immediate: true,
    },
  },
  async mounted () {
    if (this.$cycloid.permissions.canDisplay('GetServiceCatalogSources')) {
      await this.getCatalogRepositories() // needed for scs id on each card
    }

    await this.getStacks()

    const { 'infra-import': importStatus, canonical } = this.$route.query

    if (importStatus) this.queryParams = { importStatus: true }
    if (canonical) this.search = canonical
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/stack', [
      'GET_STACK',
      'GET_STACK_CONFIG',
      'VALIDATE_STACK_DEPENDENCIES',
    ]),
    ...mapMutations('organization/infraImport', [
      'SHOW_IMPORT_PROGRESS_MODAL',
    ]),
    async handleWhenSelected (stack) {
      this.closePreview()
      this.$route.name === 'stacks'
        ? this.createProjectFromStack(stack.ref)
        : this.handleStackDependencyValidation(stack)
    },
    async handleStackDependencyValidation (stack) {
      if (_.$isEmpty(stack?.dependencies)) return this.selectStackAndLoadConfig(stack)

      this.selectedStack = _.cloneDeep(stack)
      this.$toggle.showStackDependenciesErrorsModal(false)

      await this.VALIDATE_STACK_DEPENDENCIES({ stackRef: stack?.ref })

      _.$isEmpty(this.stackDependenciesErrors)
        ? this.selectStackAndLoadConfig(stack)
        : this.$toggle.showStackDependenciesErrorsModal(true)
    },
    async selectStackAndLoadConfig (stack) {
      const { ref: stackRef, name: stackName } = stack
      if (_.$isEmpty(stackRef)) return
      this.loadingConfig = true
      this.loadingStackName = stackName
      const [success] = await Promise.all([
        this.GET_STACK_CONFIG({ stackRef }),
        this.GET_STACK({ stackRef }),
      ])
      if (success) {
        this.$emit('input', { catalog: stack, config: this.stackConfig })
      }
      this.loadingConfig = false
      this.loadingStackName = ''
    },
    async getStacks () {
      this.loading = true
      const { $static: { pagination: { page, rowsPerPage } } } = this
      const pagination = createAPIPage(page, rowsPerPage)
      // TODO: FE#6435 switch to server side filtering once the import_status is supported
      const { catalogStatus = null, catalogOwn = null, trusted = null } = this.queryParams
      await this.FETCH_AVAILABLE({ keyPath: 'stacks', extraParams: [catalogStatus, catalogOwn, trusted, pagination] })
      this.loading = false
    },
    async getCatalogRepositories () {
      this.loading = true
      await this.FETCH_AVAILABLE({ keyPath: 'catalogRepositories' })
      this.loading = false
    },
    handleStackImportProgressModalShowing (stack) {
      if (_.$isEmpty(stack)) return

      this.importedStack = stack
      this.SHOW_IMPORT_PROGRESS_MODAL(true)
    },
    createProjectFromStack (stackRef) {
      this.$router.push({ name: 'projectFromStack', query: { selectedStackRef: stackRef } })
    },
    openStackModal () {
      this.$toggle.showCreateStackModal(true)
      this.$gtm.trackEvent(gtmStacksEvents.stacksAllStacksCreateANewStack)
    },
    getClickOutsideExceptions () {
      const selectors = [
        '.main-nav a',
        '.main-nav button',
        '.dev-locale-switcher__options',
        '.dev-layer',
        '.v-menu__content',
      ]
      return Array.from(document.querySelectorAll(selectors.join(', ')))
    },
    closePreview () {
      this.previewedStack = null
    },
  },
  i18n: {
    messages: {
      en: {
        beforeCreatingStacks: 'Before creating stacks',
        createCatalogRepository: 'Create catalog repository',
        createFromCommandLine: 'You can also create stacks from the command line by pushing code to a catalog repository.',
        createFromTemplate: 'Create from template',
        createNewStack: 'Create a new stack',
        createYourStack: 'Create a stack based on your existing infrastructure',
        designYourStack: 'Design your stack',
        dragAndDropResources: 'Drag and drop resources within a visual interface',
        filter: 'Filter',
        followGuideSteps: 'Follow our step-by-step guide to manually create a @:stack',
        importInfra: 'Import your infrastructure',
        loadingServiceConfig: `Loading {service}'s configuration`,
        noServiceFound: 'No stack found',
        requiredCatalogRepositories: 'Having a catalog repository is required before you can create stacks. Catalog repositories are git repositories used to stored your stacks.',
        search: 'Search by name, author, keyword or cloud provider',
        selectStackModalCancelBtn: 'Go back to stack select step',
        selectStackModalConfirmBtn: 'Continue anyway',
        selectStackModalContent: 'The following stacks are required to run <strong>{stackName}</strong> but are currently missing.',
        selectStackModalHeader: 'Missing dependency | Missing dependencies',
        selectStackModalSubContent: 'Your project may not fully work without them.',
        stacksAre: 'Stacks are versatile and reusable application templates.',
        startWithPredefinedTemplate: 'Start with a pre-defined template to get started quickly.',
        usingStacks: 'Using stacks encourages standardization across your organization, enabling people to deploy new application instances with confidence.',
      },
      es: {
        beforeCreatingStacks: 'Antes de crear stacks',
        createCatalogRepository: 'Crear repositorio de catálogo',
        createFromCommandLine: 'También puedes crear stacks desde la línea de comandos insertando código en un repositorio de catálogo.',
        createFromTemplate: 'Crear desde plantilla',
        createNewStack: 'Crear una nueva stack',
        createYourStack: 'Cree una stack basada en su infraestructura existente',
        designYourStack: 'Diseña tu stack',
        dragAndDropResources: 'Arrastra y suelta recursos dentro de una interfaz visual',
        filter: 'Filtrar',
        followGuideSteps: 'Siga nuestra guía paso a paso para crear manualmente un @:stack',
        importInfra: 'Importa tu infraestructura',
        loadingServiceConfig: 'Cargando la configuración de {service}',
        noServiceFound: 'No se encontraron stacks',
        requiredCatalogRepositories: 'Se requiere tener un repositorio de catálogo antes de poder crear stacks. Los repositorios de catálogo son repositorios de git que se utilizan para almacenar sus stacks.',
        search: 'Buscar por nombre, autor, palabra clave o cloud provider',
        selectStackModalCancelBtn: 'Volver al paso de selección de lo stack',
        selectStackModalConfirmBtn: 'De todas maneras, continúe',
        selectStackModalContent: 'Los siguientes stacks son necesarios para ejecutar <strong> {stackName} </strong>, pero faltan actualmente.',
        selectStackModalHeader: 'Falta de dependencia | Dependencias faltantes',
        selectStackModalSubContent: 'Es posible que su proyecto no funcione completamente sin ellos.',
        stacksAre: 'Las stacks son plantillas de aplicaciones versátiles y reutilizables.',
        startWithPredefinedTemplate: 'Comience con una plantilla predefinida para comenzar rápidamente.',
        usingStacks: 'El uso de stacks fomenta la estandarización en toda su organización, lo que permite a las personas implementar nuevas instancias de aplicaciones con confianza.',
      },
      fr: {
        beforeCreatingStacks: 'Avant de créer des stacks',
        createCatalogRepository: 'Créer un dépot de catalogues',
        createFromCommandLine: 'Vous pouvez également créer des stacks à partir de la ligne de commande en envoyant du code dans une source de catalogue.',
        createFromTemplate: `Créer à partir d'un modèle`,
        createNewStack: 'Créer une nouvelle stack',
        createYourStack: 'Créez une stack basée sur votre infrastructure existante',
        designYourStack: 'Concevez votre stack',
        dragAndDropResources: 'Faites glisser et déposez des ressources dans une interface visuelle',
        filter: 'Filtre',
        followGuideSteps: 'Suivez notre guide étape par étape pour créer manuellement une @:stack',
        importInfra: 'Importez votre infrastructure',
        loadingServiceConfig: 'Chargement de la configuration de {service}',
        noServiceFound: 'Aucun stack trouvé',
        requiredCatalogRepositories: 'Avoir un dépot de catalogues est nécessaire avant de pouvoir créer des stacks. Les dépots de catalogues sont des référentiels git utilisés pour stocker vos stacks.',
        search: 'Chercher par nom, auteur, mot-clé ou cloud provider',
        selectStackModalCancelBtn: `Revenir à l'étape de sélection de la stack`,
        selectStackModalConfirmBtn: 'Continuer quand même',
        selectStackModalContent: 'Les stacks suivantes sont nécessaires pour exécuter <strong>{stackName}</strong> mais sont actuellement manquantes.',
        selectStackModalHeader: 'Dépendance manquante | Dépendances manquantes',
        selectStackModalSubContent: 'Votre projet peut ne pas fonctionner complètement sans elles.',
        stacksAre: `Les stacks sont des modèles d'application polyvalents et réutilisables.`,
        startWithPredefinedTemplate: 'Commencez avec un modèle prédéfini pour démarrer rapidement.',
        usingStacks: `L'utilisation de stacks encourage la standardisation au sein de votre organisation, permettant aux utilisateurs de déployer de nouvelles instances d'application en toute confiance.`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.service-catalog-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .searchbar {
    display: flex;
    justify-content: space-between;
    gap: 0.25em 2em;

    &__search {
      flex: 3 0 auto;
    }

    &__filter {
      flex: 1 0 auto;
    }

    &__button {
      flex: 0 1 auto;
      align-self: center;
    }

    @media (max-width: 985px) {
      flex-direction: column;

      &__button {
        align-self: flex-end;
      }
    }
  }
}

.catalog-types-select {
  margin-top: 11px;
}

.catalog-types-select .v-input__control .v-input__slot {
  margin-bottom: 0;
}

.search .v-input__prepend-outer {
  margin-top: 19px;
}

.v-list-item__avatar {
  .svg-inline--fa {
    width: 24px;
    height: 24px;
  }
}

.create-stack__button {
  padding-right: 19px;
  padding-left: 22px;
}

.creation-method {
  box-sizing: border-box;
  width: 233px;
  padding: 12px;
  border: 1px solid get-color("grey");
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    border: 1px solid get-color("accent");
    box-shadow: 1px 1px 1px 0 get-color("accent", $alpha: 0.2);
  }

  &:active,
  &:focus {
    border: 1px solid get-color("accent");
    box-shadow: 1px 1px 1px 0 get-color("accent", $alpha: 0.2);
  }

  .text--default {
    margin-top: 8px;
    color: get-color("primary");
    font-size: map.get($font-sizes, "sm");
  }
}

.stack-list {
  display: grid;
  gap: 16px;
  padding-left: 0;
  list-style: none;
}

.side-panel {
  $offset: 8px;

  display: flex;
  position: fixed;
  z-index: 110;
  top: $offset;
  right: $offset;
  flex-direction: column;
  width: 830px;
  height: calc(100% - #{$offset} * 2);

  &-backdrop {
    position: fixed;
    z-index: 108;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0 0 0 / 50%);
  }
}
</style>
