import { render, staticRenderFns } from "./config-repositories.vue?vue&type=template&id=26f6c4d1&scoped=true&"
import script from "./config-repositories.vue?vue&type=script&lang=js&"
export * from "./config-repositories.vue?vue&type=script&lang=js&"
import style0 from "./config-repositories.vue?vue&type=style&index=0&id=26f6c4d1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26f6c4d1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VAutocomplete,VListItemContent,VListItemSubtitle,VListItemTitle,VProgressCircular})
