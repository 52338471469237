<template>
  <v-expand-transition>
    <div
      v-if="displaySimpleSelection && selectedUsecase"
      key="selected-usecase"
      class="selected-usecase">
      <div
        class="mb-4"
        data-cy="selected-pipeline">
        <span class="mr-1">{{ $t('environment.useCase') }}</span>
        <b class="mr-1">{{ selectedUsecase.name || inputValue }}</b>
        <span
          class="ma-0 cy-link"
          data-cy="change-pipeline"
          @click="onChangeIntent()">
          {{ $t('changeSelectedUseCase') }}
        </span>
      </div>

      <CyModal
        v-if="isDialogVisible"
        :header-title="$t('changeUseCase')"
        :cancel-btn-func="closeDialog"
        :action-btn-func="onChangeConfirm"
        :action-btn-text="$t('changeUseCase')"
        action-btn-icon=""
        modal-type="delete"
        small>
        <p>
          <b>{{ $t('switchingUseCase') }}</b><br>
          {{ $t('areYouSure') }}
        </p>
      </CyModal>
    </div>

    <div
      v-else
      key="usecase-selection"
      class="usecase-container">
      <slot name="before">
        <h4>{{ $t('chooseUseCase') }}</h4>
        <p class="text-caption mt-1">
          {{ $t('selectedStackHasMultipleUse') }}
        </p>
      </slot>

      <v-radio-group
        v-model="inputValue"
        class="usecase-radio-group">
        <CyInputsRadioCard
          v-for="(usecase, key) in usecases"
          :key="key"
          data-cy="pipeline-card"
          :value="key">
          <div
            slot="label"
            class="usecase">
            <div class="usecase__header">
              <div class="usecase__icon">
                <CyIconCredential
                  size="24"
                  :type="usecase.cloud_provider"/>
              </div>
              <strong class="d-block black--text">{{ usecase.name || key }}</strong>
            </div>
            <p class="mb-0">
              {{ usecase.description || '' }}
            </p>
          </div>
        </CyInputsRadioCard>
      </v-radio-group>
    </div>
  </v-expand-transition>
</template>

<script>
import CyInputsRadioCard from '@/components/inputs/radio-card'

export default {
  name: 'CyWizardUsecaseSelection',
  components: {
    CyInputsRadioCard,
  },
  props: {
    confirmChange: {
      type: Boolean,
      default: false,
    },
    usecases: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    displaySimpleSelection: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    isDialogVisible: false,
  }),
  computed: {
    inputValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
    selectedUsecase () {
      return _.get(this.usecases, this.inputValue, null)
    },
  },
  methods: {
    onChangeIntent () {
      if (this.confirmChange) return this.openDialog()
      this.reset()
      this.$emit('reset')
    },
    onChangeConfirm () {
      this.reset()
      this.closeDialog()
    },
    closeDialog () {
      this.isDialogVisible = false
    },
    openDialog () {
      this.isDialogVisible = true
    },
    reset () {
      this.inputValue = null
    },
  },
  i18n: {
    messages: {
      en: {
        areYouSure: 'Are you sure you want to change use case?',
        changeSelectedUseCase: 'Change selected use case',
        changeUseCase: 'Change use case',
        chooseUseCase: 'Choose the use case for this environment',
        selectedStackHasMultipleUse: 'The service you selected comes with multiple use cases, those usually handle different environment or cloud provider.',
        switchingUseCase: 'By switching use case you will lose any changes you made to the configuration.',
      },
      es: {
        areYouSure: '¿Estás seguro de que quieres cambiar la caso de uso?',
        changeSelectedUseCase: 'Cambiar el caso de uso seleccionado',
        changeUseCase: 'Cambiar caso de uso',
        chooseUseCase: 'Elija el caso de uso para este entorno',
        selectedStackHasMultipleUse: 'El servicio seleccionado contiene múltiples casos de uso, que generalmente manejan diferentes entornos o proveedores de cloud.',
        switchingUseCase: 'Al cambiar del caso de uso, perderá los cambios de configuración.',
      },
      fr: {
        areYouSure: `Voulez-vous vraiment changer de cas d'utilisation ?`,
        changeSelectedUseCase: `Changer le cas d'utilisation sélectionné`,
        changeUseCase: `Changer de cas d'utilisation`,
        chooseUseCase: `Choisissez le cas d'utilisation pour cet environnement`,
        selectedStackHasMultipleUse: `Le service sélectionné contient plusieurs cas d'utilisation, ils permettent généralement de supporter différents environnements ou fournisseurs de cloud.`,
        switchingUseCase: `En changeant de cas d'utilisation, vous perdrez les modifications apportées à la configuration.`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.usecase-container {
  max-width: 400px;
}

.usecase-radio-group > ::v-deep .v-input__control {
  flex-grow: 1;
}

.usecase {
  font-size: 12px;
  line-height: 1.2em;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.selected-usecase .cy-link {
  font-size: 10.5px;
}
</style>
