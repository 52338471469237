<template>
  <div
    v-if="hasMetRequirements"
    :class="[`cy-notification cy-notification--${theme}`, {
      'mb-0': noBottomMargin,
    }]"
    role="alert"
    data-cy="notification">
    <v-icon
      class="cy-notification__icon"
      data-cy="notification-icon"
      aria-label="icon"
      role="icon">
      {{ icon }}
    </v-icon>
    <div class="cy-notification__main">
      <span
        v-if="title"
        class="cy-notification__title"
        data-cy="notification-title">
        {{ _.upperFirst(title) }}
      </span>

      <section
        class="cy-notification__content content"
        data-cy="notification-content">
        <slot v-if="$slots.default"/>

        <template v-else-if="isSole">
          <span
            v-if="_.get(parsedContent, 'message')"
            class="content__message"
            v-html="$sanitizeHtml(_.get(parsedContent, 'message'))"/>
          <ul
            v-if="_.get(parsedContent, 'details')"
            class="content__details">
            <li
              v-for="(detailMessage, detailIndex) of parsedContent.details"
              :key="detailIndex"
              v-html="$sanitizeHtml(detailMessage)"/>
          </ul>
        </template>

        <ul
          v-else
          class="content__details">
          <li
            v-for="({ message, details }, contentIndex) of parsedContent"
            :key="contentIndex">
            <span v-html="$sanitizeHtml(message)"/>
            <ul v-if="details">
              <li
                v-for="(detailMessage, detailIndex) of details"
                :key="detailIndex"
                v-html="$sanitizeHtml(detailMessage)"/>
            </ul>
          </li>
        </ul>
      </section>
    </div>

    <div class="cy-notification__actions">
      <CyButton
        v-if="buttonLabel"
        :theme="actionButtonTheme"
        variant="tertiary"
        data-cy="click-notification-button"
        @click="$emit('click', $event)">
        {{ buttonLabel }}
      </CyButton>

      <CyButton
        v-if="closeable"
        class="close-btn"
        theme="primary"
        variant="tertiary"
        data-cy="close-notification-button"
        aria-label="close"
        icon="close"
        icon-only
        sm
        @click="$emit('close', $event)"/>
    </div>
  </div>
</template>

<script>
export const THEMES = ['error', 'warning', 'info', 'success']
export const NOTIFICATION_ICONS = {
  error: 'error',
  warning: 'warning',
  info: 'info',
  success: 'check_circle',
}

export const DEFAULT_BUTTON_THEME = 'secondary'
export const DEFAULT_THEME = 'info'
export const DEFAULT_ICON = NOTIFICATION_ICONS[DEFAULT_THEME]

/**
 * Notification component
 *
 * ! At least one of the following must be populated in order for the notification to render:
 *  - prop: title
 *  - prop: content
 *  - slot: default
 *
 * Optional
 * --------
 * @prop {String}         [theme='info']          Theme/main colouring of the notification.
 *                                                  ! must be 1 of: ['info', 'error', 'warning', 'success']
 * @prop {String}         [title='']              The notification title.
 * @prop {String, Array}  [content='']            When an Array of [String|Object], show as a list.
 *                                                When a String, show as a span.
 * @prop {String}         [buttonLabel='']        Text for the action button
 * @prop {Boolean}        [closeable=false]       Adds the × icon and ability to close/hide the notification
 * @prop {Boolean}        [noBottomMargin=false]  Removes the default bottom margin
 *
 * @slot [default]        When you want to override all content (not icon or action btns)
 *
 * @event [click]         When the action button is clicked
 * @event [close]         When the notification is closed
 *
 * @link Figma: https://www.figma.com/file/ijs1fHkcMb3Q9AqKwiTQ7u/Component-library?node-id=8%3A16679
 */
export default {
  name: 'CyNotification',
  props: {
    theme: {
      type: String,
      validator: (theme) => THEMES.includes(theme),
      default: DEFAULT_THEME,
    },
    title: {
      type: String,
      default: '',
    },
    content: {
      type: [String, Array],
      default: '',
    },
    buttonLabel: {
      type: String,
      default: '',
    },
    closeable: {
      type: Boolean,
      default: false,
    },
    noBottomMargin: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasMetRequirements () {
      const { title, parsedContent, $slots } = this
      if (_.every([$slots?.default, parsedContent], _.size)) console.warn('[CyNotification] both the default slot and the content prop are populated. They cannot mutually exist, the slot will override the content prop. Remove the prop or the slot.')
      return _.some([title, $slots?.default, parsedContent], _.size)
    },
    actionButtonTheme () {
      return _.without(THEMES, 'info').includes(this.theme) ? this.theme : DEFAULT_BUTTON_THEME
    },
    icon () {
      return NOTIFICATION_ICONS[this.theme] || DEFAULT_THEME
    },
    isSole () {
      return _.has(this.parsedContent, 'message')
    },
    parsedContent () {
      const content = _.castArray(this.content)

      if (!content.length) return []

      const parsedContent = _.transform(content, (acc, cur) => {
        if (_.isEmpty(cur)) return
        acc.push({
          message: cur?.userMessage ?? cur?.message ?? cur,
          details: cur?.details,
        })
      }, [])
      return parsedContent.length === 1 ? _.first(parsedContent) : parsedContent
    },
  },
}
</script>

<style lang="scss" scoped>
@import "styles/variables/cynotification";

.cy-notification {
  display: grid;
  grid-template: "icon content actions";
  grid-template-columns: 30px auto;
  margin-bottom: 1em;
  padding: 8.5px 8.5px 8.5px 19px;
  border-radius: 8px;

  + .cy-notification {
    margin-top: 6px;
  }

  @each $theme in map-keys($theme-variants) {
    &--#{$theme} {
      $config: map.get($theme-variants, $theme);

      background: map.get($config, "background");
      color: map.get($config, "icon-color");

      ::v-deep a {
        color: map.get($config, "color");
        font-weight: 700;

        &:visited {
          color: map.get($config, "visited-color");
        }

        &:hover {
          color: map.get($config, "hover-color");
        }
      }
    }
  }

  &__icon,
  &__main {
    padding: 8px 0;
  }

  &__icon {
    grid-area: icon;
    align-items: flex-start;
    justify-content: flex-start;
    color: currentColor !important;
    font-size: 20px;
  }

  &__title {
    color: get-color("primary");
    font-weight: 700;
  }

  &__content {
    display: inline;
    grid-area: content;
    flex: 1 0 auto;
    align-self: center;
    color: get-color("primary");
    font-size: 14px;
    word-break: break-word;

    .content {
      &__details {
        padding-inline-start: 16px;
      }

      &__api-error:not(:first-child) {
        display: block;
        margin-top: 1em;
      }
    }
  }

  &__actions {
    display: flex;
    grid-area: actions;
    flex: 1 1 auto;
    margin-left: 16px;
    justify-self: flex-end;

    ::v-deep .v-btn.v-btn.cy-btn {
      align-self: flex-start;
      font-weight: bold;
    }

    .close-btn {
      margin-top: 3px;
    }
  }
}
</style>
