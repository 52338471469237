<template>
  <section class="my-6 pt-2">
    <div
      v-for="(sections, environment) in formsConfig"
      :key="`environment-${environment}`"
      class="environment__container">
      <span class="font-weight-bold">
        {{ environment }}
      </span>
      <v-row
        no-gutters
        class="environment__content">
        <div
          v-for="(groups, sectionName) in sections"
          :key="`technology-${sectionName}`"
          class="technology__container my-4">
          <h3 class="technology__heading">
            {{ _.upperFirst(sectionName) }}
          </h3>
          <template v-for="group in groups">
            <div
              v-for="(value, name) in group"
              :key="`variable-${name}`"
              :class="['variable', {
                'variable--has-module d-block': hasModule(name),
              }]">
              <h4
                v-if="_.lowerCase(sectionName) === 'terraform' && hasModule(name)"
                :class="['module-name', { 'd-none': !isFirstModuleVariable(groups, name) }]">
                {{ stripModule(name) }}
              </h4>
              <span class="variable__key">{{ _.lowerCase(sectionName) === 'terraform' ? stripVariableName(name) : name }}:</span>
              <kbd
                v-if="!_.$isEmpty(value)"
                :class="['variable__value', { 'variable__value--pre-wrap': needsPreWrap(value) }]">
                {{ value }}
              </kbd>
            </div>
          </template>
        </div>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CyWizardFormsSummary',
  props: {
    formsConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    hasModule (variableName) {
      return (variableName.split('.')).length > 1
    },
    isFirstModuleVariable (variables, variableName) {
      const moduleName = this.stripModule(variableName)
      const moduleVars = _.filter(_.keys(variables), (variableKey) => _.split(variableKey, '.')[1] === moduleName)
      return moduleVars[0] === variableName
    },
    stripModule (variableName) {
      const variableChunks = variableName.split('.')
      return variableChunks.length > 1 ? variableName.split('.')[1] : ''
    },
    stripVariableName (variableName) {
      const variableChunks = variableName.split('.')
      return variableChunks.length > 1 ? variableName.split('.')[2] : variableName
    },
    needsPreWrap (value) {
      return _.isObject(value) && _.keys(value).length > 5
    },
  },
}
</script>

<style lang="scss" scoped>
.environment {
  &__container {
    display: inline-flex;
    flex-direction: column;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 20px;

    .technology {
      &__container {
        margin-right: 40px;

        .variable {
          margin: 3px 0;
          padding-left: 15px;
          color: get-color("grey", "dark-2");
          line-height: 30px;

          &__key {
            color: get-color("primary");
          }

          &__value {
            padding-top: 0;
            background-color: inherit !important;
            box-shadow: none;
            color: inherit;
            font-weight: 500;
            line-height: 30px;

            &::before,
            &::after {
              display: none;
            }

            &--pre-wrap {
              white-space: pre-wrap;
            }
          }

          &--has-module {
            .module-name {
              color: get-color("primary");
              font-size: 16px;
              font-weight: 500;
            }

            .variable__key {
              margin-left: 15px;
            }
          }
        }
      }

      &__heading {
        height: 24px;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
