import { render, staticRenderFns } from "./usecase-selection.vue?vue&type=template&id=fa637b98&scoped=true&"
import script from "./usecase-selection.vue?vue&type=script&lang=js&"
export * from "./usecase-selection.vue?vue&type=script&lang=js&"
import style0 from "./usecase-selection.vue?vue&type=style&index=0&id=fa637b98&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa637b98",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VExpandTransition,VRadioGroup})
