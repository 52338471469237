<template>
  <div class="cy-inputs-credentials__container">
    <v-autocomplete
      :value="selectedCredential"
      v-bind="$attrs"
      class="cy-widget-input cy-inputs-credentials__autocomplete is-x-large"
      data-cy="credentials-select"
      item-value="canonical"
      persistent-hint
      :error-messages="getErrors"
      :filter="filterItems"
      :hint="hint"
      :items="filteredCredentials"
      :placeholder="$t('credentialsPlaceholder')"
      :readonly="loading || $attrs.readonly"
      @blur="$v.value.$touch()"
      @change="selectCredential">
      <template slot="prepend-inner">
        <v-list-item-avatar
          v-if="selectedCredential.type"
          :size="24"
          rounded>
          <CyIconCredential
            :key="`${selectedCredential.type}-${selectedCredential.name}`"
            size="24"
            no-margin
            :type="selectedCredential.type"/>
        </v-list-item-avatar>
      </template>

      <template #selection="data">
        {{ data.item.name }}
      </template>

      <template #item="data">
        <v-list-item-avatar
          :size="24"
          rounded>
          <CyIconCredential
            size="24"
            no-margin
            :type="data.item.type"/>
        </v-list-item-avatar>
        <v-list-item-content data-cy="credentials-item">
          <v-list-item-title>{{ data.item.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ data.item.canonical }}</v-list-item-subtitle>
        </v-list-item-content>
      </template>

      <template
        v-if="loading"
        slot="append">
        <v-progress-circular
          size="22"
          indeterminate
          color="secondary"/>
      </template>
    </v-autocomplete>

    <CyFormsWidgetAutocomplete
      v-if="isKeyInputVisible"
      v-model="selectedKey"
      v-bind="$attrs"
      clearable
      large
      class="cy-inputs-credentials__keys"
      width="is-large"
      :items="credentialKeys"
      :allow-free-text="false"
      @input="selectKey"/>
  </div>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'
import CyFormsWidgetAutocomplete from '@/components/forms-widget/autocomplete'

export default {
  name: 'CyFormsWidgetCredentials',
  components: {
    CyFormsWidgetAutocomplete,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    technology: {
      type: String,
      default: 'pipeline',
    },
    credTypes: {
      type: Array,
      default: () => [],
    },
    displayKeys: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    value: {
      required: requiredIf(function () {
        return this.required
      }),
    },
  },
  data: () => ({
    selectedCredential: {},
    selectedKey: '',
    loading: false,
    credentialsFromAPI: [],
    credentialFromAPI: {},
    errorsFromAPI: null,
  }),
  computed: {
    credentialKeys () {
      const keys = this.credentialFromAPI?.keys || []
      return keys.sort()
    },
    filteredCredentials () {
      if (_.isEmpty(this.credTypes)) return this.credentialsFromAPI
      return _.filter(this.credentialsFromAPI, ({ type }) => _.includes(this.credTypes, type))
    },
    hint () {
      return this.fullPath || this.$t('selectCredentialHint')
    },
    getErrors () {
      const errors = []
      const { $dirty, required: $vRequired } = this.$v.value
      if (this.loading) return errors
      if (!_.isEmpty(this.errorsFromAPI)) return _.map(this.errorsFromAPI, ({ message }) => message)
      if ($dirty && !$vRequired && this.required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    isCredentialSelected () {
      return !_.isEmpty(this.selectedCredential)
    },
    isKeyInputVisible () {
      const { displayKeys, isCredentialSelected, loading } = this
      return isCredentialSelected && !loading && displayKeys
    },
    fullPath () {
      const { displayKeys, selectedCredential: { path }, selectedKey } = this
      if (!displayKeys || _.$isEmpty(selectedKey)) return path
      if (_.$isEmpty(path)) return null
      return `${path}${this.pathSeparator}${selectedKey}`
    },
    pathSeparator () {
      return this.technology === 'pipeline' ? '.' : '/'
    },
  },
  watch: {
    credTypes () {
      this.resetWidgetState()
    },
  },
  async mounted () {
    await this.resetWidgetState()
  },
  methods: {
    filterItems (item, queryText) {
      const { name = '', type = '' } = item
      const searchText = queryText.toLowerCase()
      return name.toLowerCase().includes(searchText) || type.toLowerCase().includes(searchText)
    },
    async getCredentialsFromAPI () {
      this.loading = true
      this.errorsFromAPI = null
      const { data = [], errors } = await this.$cycloid.ydAPI.listCredentials(this.orgCanonical) || {}

      if (!_.isEmpty(data)) {
        this.credentialsFromAPI = _.sortBy(data, ['type', 'path'])
      }

      if (this.filteredCredentials.length === 1) {
        await this.selectCredential(_.first(this.filteredCredentials).canonical)
        this.selectKey(_.first(this.credentialKeys))
      }

      this.errorsFromAPI = errors
      this.loading = false
    },
    async getCredentialFromAPI () {
      this.loading = true
      this.errorsFromAPI = null

      const { orgCanonical, selectedCredential: { canonical: credentialCanonical } } = this
      const { data = [], errors } = await this.$cycloid.ydAPI.getCredential(orgCanonical, credentialCanonical) || {}

      if (!_.isEmpty(data)) this.credentialFromAPI = data
      this.errorsFromAPI = errors
      this.loading = false
    },
    async resetWidgetState () {
      this.selectedCredential = {}
      this.selectedKey = ''
      this.loading = false
      this.credentialsFromAPI = []
      this.credentialFromAPI = {}
      this.errorsFromAPI = null

      await this.getCredentialsFromAPI()
      await this.selectCredentialFromPath()
    },
    selectKey (key = '') {
      this.selectedKey = key
      const separatorAndKey = !_.$isEmpty(key) ? `${this.pathSeparator}${key}` : ''
      const pathKey = `${this.selectedCredential.path}${separatorAndKey}`
      const finalPathKey = this.technology === 'pipeline' ? `((${pathKey}))` : pathKey
      this.$emit('input', finalPathKey)
    },
    async selectCredential (credentialCanonical) {
      const { displayKeys, technology } = this
      const credential = _.find(this.filteredCredentials, ['canonical', credentialCanonical])
      const path = _.$get(credential, 'path', '')
      this.selectedCredential = credential || {}

      if (_.isEmpty(credential)) return this.$emit('input', '')
      if (displayKeys) {
        await this.getCredentialFromAPI()
        this.selectKey()
        return
      }
      const finalPathKey = technology === 'pipeline' ? `((${path}))` : path
      this.$emit('input', finalPathKey)
    },
    async selectCredentialFromPath () {
      if (_.isEmpty(this.value)) return

      const [credentialPath, credentialKey] = this.value.replace(/[()']/g, '').split(this.pathSeparator)
      const credential = _.find(this.filteredCredentials, ['path', credentialPath])
      this.selectedCredential = credential || {}

      if (_.isEmpty(this.selectedCredential)) this.$emit('input', '')
      else {
        await this.getCredentialFromAPI()
        if (this.displayKeys) this.selectKey(credentialKey)
      }
    },
  },
  i18n: {
    messages: {
      en: {
        credentialsPlaceholder: 'Select a credential',
        selectCredentialHint: 'Please select a credential from the list above.',
      },
      es: {
        credentialsPlaceholder: 'Seleccione una credential',
        selectCredentialHint: 'Por favor, seleccione una credencial en la lista de arriba.',
      },
      fr: {
        credentialsPlaceholder: 'Sélectionnez un identifiant',
        selectCredentialHint: 'Veuillez sélectionner un identifiant dans la liste ci-dessus.',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
$width-short: 121px;
$width-large: 228px;
$width-x-large: 440px;
$size-variants: (
  "is-large": $width-large,
  "is-x-large": $width-x-large
);

@mixin media-query-full-width() {
  @media screen and (max-width: 745px) {
    width: 100%;
  }
}

.v-select-list {
  ::v-deep {
    > div {
      padding-right: 1rem;
      padding-left: 1rem;
    }

    .v-list-item {
      &__avatar {
        min-width: auto;
        margin: 0 12px 0 0;
      }

      &__content {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}

.cy-inputs-credentials {
  &__container {
    display: flex;
    flex-flow: row wrap;

    @media screen and (max-width: 1340px) {
      flex-flow: column wrap;
    }

    .v-list-item__avatar {
      margin: 6px 4px 6px 12px;
    }
  }

  &__keys {
    display: flex;
    flex-grow: 0;
  }

  &__autocomplete {
    flex: inherit;
    margin-right: 8px;
    overflow: visible;

    ::v-deep {
      .v-input__control {
        margin-bottom: 8px;

        .v-input__prepend-inner,
        .v-input__append-inner {
          margin: auto;
        }

        .v-input__prepend-inner {
          .v-list-item__avatar {
            min-width: auto;
          }
        }

        .v-select {
          &__selections {
            margin-top: 0;
            padding-top: 0;

            .v-select__selection--comma {
              display: inline-block;
              width: 148px;

              @extend %ellipsis;
            }

            input {
              padding-left: 1rem;
            }
          }

          &__slot {
            padding-right: 1rem;
          }
        }
      }
    }

    @each $name, $width in $size-variants {
      &.#{$name} {
        width: $width;

        @include media-query-full-width;

        ::v-deep {
          .v-select__selections {
            width: $width - 100px;

            @include media-query-full-width;

            .v-select__selection--comma {
              width: $width;

              @include media-query-full-width;
            }
          }
        }
      }
    }
  }
}
</style>
