<template>
  <CyTooltip
    :value="isTooltipActive"
    :disabled="disabled || !isTooltipActive"
    :close-delay="showTooltip.hint ? 0 : closeDelay"
    :top="isTooltipDirection.top"
    :right="isTooltipDirection.right"
    :bottom="isTooltipDirection.bottom"
    :left="isTooltipDirection.left"
    :icon="showTooltip.copied ? 'check' : ''"
    :content-class="`cy-copy-btn__tooltip--${showTooltip.hint ? 'hint' : 'copied'}`">
    <template #activator="{ on }">
      <button
        v-clipboard:copy="copyValue"
        v-clipboard:success="copy"
        type="button"
        class="cy-copy-btn"
        :disabled="disabled"
        v-on="isOnBindable ? on : null"
        @mouseenter="showHint"
        @mouseleave="hideHint"
        @click.prevent.stop="copy">
        <v-icon
          color="grey"
          :small="small">
          content_copy
        </v-icon>
      </button>
    </template>
    <span v-show="showTooltip.hint">
      {{ $t('willCopy') }} <code>{{ copyHint }}</code>
    </span>
    <span v-show="showTooltip.copied">
      {{ $t('copied') }}
    </span>
  </CyTooltip>
</template>

<script>
export const DEFAULT_DIRECTION = 'bottom'
export const DEFAULT_TABLE_DIRECTION = 'left'

export default {
  name: 'CyCopyBtn',
  props: {
    copyValue: {
      type: String,
      required: true,
    },
    copyHint: {
      type: String,
      default: '',
    },
    closeDelay: {
      type: Number,
      default: 2000,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    timeoutHandler: null,
    visibleTooltip: '',
  }),
  computed: {
    isTooltipDirection () {
      const { top, right, bottom, left } = this
      const hasAttrProvided = _.some([top, right, bottom, left])

      const isTooltipDirection = {
        top,
        right,
        bottom,
        left,
      }
      if (hasAttrProvided) return isTooltipDirection

      if (this.isInTable) isTooltipDirection[DEFAULT_TABLE_DIRECTION] = true
      else isTooltipDirection[DEFAULT_DIRECTION] = true
      return isTooltipDirection
    },
    isInTable () {
      return this.$parent.$options.name.includes('table')
    },
    parentName () {
      return this.$parent.$options.name
    },
    isOnBindable () {
      return !_.some([
        this.isInTable,
        this.parentName.includes('card'),
        _.isEqual(this.parentName, 'CyInfraViewProperty'),
      ])
    },
    isTooltipActive () {
      return _.some([this.showTooltip.hint, this.showTooltip.copied])
    },
    showTooltip () {
      return { [this.visibleTooltip]: true }
    },
  },
  methods: {
    copy ({ target = {} }) {
      const allowCopy = [...(target.classList || [])].includes('v-icon')
      // to stop propagation problems when using in checkbox label etc
      if (!allowCopy) return

      this.visibleTooltip = 'copied'
      if (this.timeoutHandler) clearTimeout(this.timeoutHandler)
      this.timeoutHandler = setTimeout(() => { this.visibleTooltip = '' }, this.closeDelay)
    },
    showHint () {
      if (this.copyHint && !this.showTooltip.copied) this.visibleTooltip = 'hint'
    },
    hideHint () {
      if (this.visibleTooltip === 'copied') return
      this.visibleTooltip = ''
    },
  },
  i18n: {
    messages: {
      en: {
        copied: 'Copied!',
        willCopy: 'Will copy',
      },
      es: {
        copied: 'Copiado!',
        willCopy: 'Copiará',
      },
      fr: {
        copied: 'Copié!',
        willCopy: 'Copiera',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.v-icon:hover {
  color: get-color("secondary") !important;
}
</style>
