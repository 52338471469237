<template>
  <component
    ref="autocomplete"
    v-bind="$attrs"
    :value="value"
    :class="['cy-widget-input', 'cy-inputs-autocomplete', width]"
    :error-messages="getErrors"
    :required="required"
    :items="items"
    @blur="$v.value.$touch()"
    @input="$emit('input', $event) && $nextTick(() => $refs.autocomplete.blur())"
    @update:searchInput="updateSearchInput($event) && $refs.autocomplete.blur()"
    :is="allowFreeText ? 'v-combobox' : 'v-autocomplete'">
    <template
      v-if="loading"
      slot="append">
      <v-progress-circular
        size="22"
        indeterminate
        color="secondary"/>
    </template>
  </component>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'
import { VCombobox, VAutocomplete } from 'vuetify/lib'

export default {
  name: 'CyFormsWidgetAutocomplete',
  components: {
    VCombobox,
    VAutocomplete,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: 'is-x-large',
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    allowFreeText: {
      type: Boolean,
      default: true,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  validations: {
    value: {
      required: requiredIf(function () {
        return this.required
      }),
    },
  },
  computed: {
    getErrors () {
      // External error messages from props take precedence
      if (!_.$isEmpty(this.errorMessages)) return this.errorMessages

      const errors = []
      const { $dirty, required } = this.$v.value
      if (!$dirty) return errors
      if (this.required && !required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
  },
  methods: {
    updateSearchInput (searchText) {
      // vuetify autocomplete needs 3 backspace hits to actually emit
      // the input event with an empty value, that's why this "hack"
      if (searchText === '') {
        this.$emit('input', '')
        this.$v.$touch()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "styles/variables/forms";

$width-large: 228px;
$width-x-large: 440px;
$size-variants: (
  "is-large": $width-large,
  "is-x-large": $width-x-large
);

@mixin media-query-full-width() {
  @media screen and (max-width: 745px) {
    width: 100%;
  }
}

::v-deep .v-list-item {
  padding-right: 1rem;
  padding-left: 1rem;
}

.cy-inputs-autocomplete {
  ::v-deep {
    .v-input__control {
      input {
        padding-right: 1rem;
        padding-left: 1rem;
      }

      .v-input__append-inner {
        margin-top: 4px;
      }

      .v-select {
        &__selections {
          margin-top: 0;
          padding-top: 0;

          &--comma {
            display: inline-block;
            width: 148px;

            @extend %ellipsis;
          }
        }

        &__slot {
          margin-right: 1rem;
        }
      }
    }
  }

  @each $name, $width in $size-variants {
    &.#{$name} {
      width: $width;

      @include media-query-full-width;

      ::v-deep {
        .v-select__selections {
          width: $width - 50px;

          @include media-query-full-width;

          .v-select__selection--comma {
            width: $width;

            @include media-query-full-width;
          }
        }
      }
    }
  }

  &.v-text-field--full-width {
    width: 100%;
    height: $widget-input-height;

    ::v-deep {
      .v-select__selections {
        .v-select__selection--comma {
          width: auto;
          overflow: visible;
          text-overflow: none;
        }
      }
    }
  }
}
</style>
