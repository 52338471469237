<template>
  <v-autocomplete
    :value="selectedCatalogRepository"
    v-bind="$attrs"
    class="cy-widget-input cy-inputs-catalog-repositories__autocomplete is-x-large"
    item-value="url"
    persistent-hint
    :error-messages="getErrors"
    :filter="filterItems"
    :hint="catalogRepositoryURLHint"
    :items="catalogRepositoriesFromAPI"
    :placeholder="$t('catalogRepositoriesPlaceholder')"
    @blur="$v.value.$touch()"
    @change="selectCatalogRepository">
    <template #selection="data">
      {{ data.item.name }}
    </template>

    <template #item="data">
      <v-list-item-content>
        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
        <v-list-item-subtitle>{{ data.item.url }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>

    <template
      v-if="loading"
      slot="append">
      <v-progress-circular
        size="22"
        indeterminate
        color="secondary"/>
    </template>
  </v-autocomplete>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'CyFormsWidgetCatalogRepositories',
  props: {
    value: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    value: {
      required: requiredIf(function () {
        return this.required
      }),
    },
  },
  data: () => ({
    loading: false,
    selectedCatalogRepository: {},
    catalogRepositoriesFromAPI: [],
    errorsFromAPI: null,
  }),
  computed: {
    catalogRepositoryURLHint () {
      return _.get(this.selectedCatalogRepository, 'url', this.$t('selectCatalogRepositoryHint'))
    },
    getErrors () {
      const errors = []
      const { $dirty, required } = this.$v.value
      if (!_.isEmpty(this.errorsFromAPI)) return _.map(this.errorsFromAPI, ({ message }) => message)
      if (!$dirty) return errors
      if (this.required && !required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
  },
  async mounted () {
    await this.getCatalogRepositoriesFromAPI()
    await this.selectCatalogRepositoryFromValue()
  },
  methods: {
    async getCatalogRepositoriesFromAPI () {
      this.loading = true
      this.errorsFromAPI = null

      const { data = [], errors } = await this.$cycloid.ydAPI.getServiceCatalogSources(this.orgCanonical) || {}

      if (!_.isEmpty(data)) this.catalogRepositoriesFromAPI = _.sortBy(data, ['name', 'url'])
      if (this.catalogRepositoriesFromAPI.length === 1) this.selectCatalogRepository(_.first(this.catalogRepositoriesFromAPI).url)
      this.errorsFromAPI = errors
      this.loading = false
    },
    selectCatalogRepository (catalogRepositoryUrl) {
      const catalogRepository = _.find(this.catalogRepositoriesFromAPI, ({ url }) => url === catalogRepositoryUrl) || {}
      const { url = '', credential_canonical: credentialCanonical = '' } = catalogRepository
      this.selectedCatalogRepository = catalogRepository

      this.$emit('input', url)
      this.$emit('credential-change', credentialCanonical)
      this.$v.value.$touch()
    },
    async selectCatalogRepositoryFromValue () {
      this.selectedCatalogRepository = _.first(this.catalogRepositoriesFromAPI.filter(({ url }) => url === this.value)) || {}

      if (!_.isEmpty(this.selectedCatalogRepository)) {
        this.$emit('credential-change', _.get(this.selectedCatalogRepository, 'credential_canonical', ''))
      } else {
        this.$emit('input', '')
      }
    },
    filterItems (item, queryText) {
      const { name = '', url = '' } = item
      const searchText = queryText.toLowerCase()
      return name.toLowerCase().includes(searchText) || url.toLowerCase().includes(searchText)
    },
  },
  i18n: {
    messages: {
      en: {
        catalogRepositoriesPlaceholder: 'Select a catalog repository',
        selectCatalogRepositoryHint: 'Please select a catalog repository from the list above.',
      },
      es: {
        catalogRepositoriesPlaceholder: 'Seleccione un respositorio de catálogos',
        selectCatalogRepositoryHint: 'Por favor, seleccione un respositorio de catálogos en la lista de arriba.',
      },
      fr: {
        catalogRepositoriesPlaceholder: 'Sélectionnez un référentiel de catalogue',
        selectCatalogRepositoryHint: 'Veuillez sélectionner un référentiel de catalogue dans la liste ci-dessus.',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
$width-x-large: 440px;

@mixin media-query-full-width() {
  @media screen and (max-width: 745px) {
    width: 100%;
  }
}

::v-deep .v-list-item__content {
  padding: 0;
}

.cy-inputs-catalog-repositories {
  &__autocomplete {
    display: flex;
    flex-direction: row;
    overflow: visible;

    ::v-deep {
      .v-input__control {
        .v-text-field__details {
          min-width: 500px;
        }

        .v-input__prepend-inner,
        .v-input__append-inner {
          margin-top: 6px;
        }

        .v-select {
          &__selections {
            margin-top: 0;
            padding-top: 0;
          }

          &__selection--comma {
            display: inline-block;
            width: 148px;

            @extend %ellipsis;
          }

          &__slot {
            margin: 0 1rem;
          }
        }
      }
    }

    &.is-x-large {
      width: $width-x-large;

      @include media-query-full-width;

      ::v-deep {
        .v-select__selections {
          width: $width-x-large - 100px;

          @include media-query-full-width;

          .v-select__selection--comma {
            width: $width-x-large;

            @include media-query-full-width;
          }
        }
      }
    }
  }
}
</style>
